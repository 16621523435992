<template>
  <el-main>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="订单编号：">
        <el-input v-model="searchForm.order_sn" size="small" placeholder="请输入订单编号"></el-input>
      </el-form-item>
      <el-form-item label="订单类型：">
        <el-select v-model="searchForm.type" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option label="时价" :value="1"></el-option>
          <el-option label="合伙人入驻" :value="2"></el-option>
          <el-option label="项目合作" :value="3"></el-option>
          <el-option label="项目活动" :value="4"></el-option>
          <el-option label="应用数据" :value="5"></el-option>
          <el-option label="实物商品" :value="6"></el-option>
          <el-option label="广告" :value="7"></el-option>
          <el-option label="关键词" :value="8"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户昵称：">
        <el-input v-model="searchForm.nickname" size="small" placeholder="请输入用户昵称"></el-input>
      </el-form-item>
      <el-form-item label="会员姓名：">
        <el-input v-model="searchForm.name" size="small" placeholder="请输入会员姓名"></el-input>
      </el-form-item>
      <el-form-item label="会员电话：">
        <el-input v-model="searchForm.mobile" size="small" placeholder="请输入会员电话"></el-input>
      </el-form-item>
      <el-form-item label="交易时间：">
        <el-date-picker
          v-model="searchForm.start_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="total-num">
      <div>筛选后订单支付金额：￥{{ total_pay_amount }}</div>
      <div>筛选后订单支付积分：￥{{ total_pay_integral }}</div>
      <div>筛选后平台收益金额：￥{{ total_amount }}</div>
    </div>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="create_time" label="交易时间" width="200" align="center">
        <template v-slot="{ row }">{{ row.create_time | getDateformat }}</template>
      </el-table-column>
      <el-table-column prop="order_sn" label="订单号" width="210" align="center"></el-table-column>
      <el-table-column prop="identity_type" label="交易类型" width="150" align="center">
        <template v-slot="{ row }">
          {{ row.type | orderName }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="所属会员姓名" width="180" align="center"></el-table-column>
      <el-table-column prop="mobile" label="会员联系电话" width="200" align="center"></el-table-column>
      <el-table-column prop="nickname" label="下单用户信息" width="200" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.nickname" :avatar="row.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="order_amount" label="订单金额" width="150" align="center">
        <template v-slot="{ row }">￥{{ row.order_amount }}</template>
      </el-table-column>
      <el-table-column prop="pay_amount" label="订单支付金额" width="150" align="center">
        <template v-slot="{ row }">￥{{ row.pay_amount }}</template>
      </el-table-column>
      <el-table-column prop="pay_integral" label="支付积分" width="150" align="center">
        <template v-slot="{ row }">￥{{ row.pay_integral }}</template>
      </el-table-column>
      <el-table-column prop="amount" label="平台收益金额(此金额包含积分)" width="220" align="center">
        <template v-slot="{ row }">￥{{ row.amount }}</template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import userInfo from '@/components/userInfo';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
  components: {
    userInfo,
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        order_sn: '',
        start_time: '',
        end_time: '',
        type: '',
        nickname: '',
        name: '',
        mobile: '',
      },
      list: [],
      total_number: 0,
      total_pay_amount: 0,
      total_pay_integral: 0,
      total_amount: 0,
    };
  },
  filters: {
    getDateformat: getDateformat,
    orderName(type) {
      let name = '';
      switch (type) {
        case 1:
          name = '时价';
          break;
        case 2:
          name = '合伙人入驻';
          break;
        case 3:
          name = '项目合作';
          break;
        case 4:
          name = '项目活动';
          break;
        case 5:
          name = '应用数据';
          break;
        case 6:
          name = '实物商品';
          break;
        case 7:
          name = '广告';
          break;
        case 8:
          name = '关键词';
          break;
      }
      return name;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    searchClear() {
      this.searchForm = {
        page: 1,
        rows: 10,
        order_sn: '',
        start_time: '',
        end_time: '',
        type: '',
        nickname: '',
        name: '',
        mobile: '',
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.start_time = searchForm.start_time[1] / 1000;
        searchForm.end_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.gateway.finance.platformRevenue,searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
          this.total_pay_amount = res.result.total_pay_amount;
          this.total_pay_integral = res.result.total_pay_integral;
          this.total_amount = res.result.total_amount;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .total-num {
    display: flex;
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 15px;
    div {
      margin-right: 40px;
    }
  }
}
</style>